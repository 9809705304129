import "@/theme/app.scss";

import { IonicVue } from "@ionic/vue";
import { createErrorHandler } from "@packages/error-handler";
import * as Sentry from "@sentry/vue";
import { createApp } from "vue";
import { createGtag } from "vue-gtag";

import { errorHandler as apiErrorHandler } from "@/api";
import App from "@/App.vue";
import { isPlayerError, useToasts } from "@/composables";
import { router } from "@/router";
import { pinia } from "@/stores";

export const playerErrorHandler = createErrorHandler((error) => {
  const toasts = useToasts();

  if (isPlayerError(error)) {
    toasts.showError(error.message);
    return;
  }
});

const app = createApp(App);

if (import.meta.env.PUBLIC_SENTRY_DSN) {
  Sentry.init({
    app,
    dsn: import.meta.env.PUBLIC_SENTRY_DSN,
    environment: import.meta.env.MODE,
    transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
    integrations: [
      Sentry.extraErrorDataIntegration({ captureErrorCause: true }),
      Sentry.httpClientIntegration(),
    ],
    sendDefaultPii: true,
  });

  Sentry.setContext("app", {
    version: import.meta.env.PACKAGE_VERSION,
  });
}

if (import.meta.env.PUBLIC_GOOGLE_ANALYTICS_ID) {
  app.use(
    createGtag({
      tagId: import.meta.env.PUBLIC_GOOGLE_ANALYTICS_ID,
      pageTracker: { router },
      appName: import.meta.env.PUBLIC_APP_NAME,
      config: {
        params: {
          app_version: import.meta.env.PACKAGE_VERSION,
        },
      },
    }),
  );
}

app
  .use(apiErrorHandler)
  .use(playerErrorHandler)
  .use(IonicVue)
  .use(pinia)
  .use(router)
  .mount("#app");
